@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/_mixins";
@import "react-datepicker/dist/react-datepicker.css";
@import "remirror/styles/all.css";

//Colors
$green: #2ecd99;
$yellow: #f0c541;
$red: #ed6f56;
$blue: #4e9de6;
$pink: #f1a1c7;
$orange: #f3a464;
$gold: #c4996d;
$silver: #83878d;
$black: #212121;
$grey: #878787;
$light_grey: #efefef;
$menu_bg_col_1: #2a3e4c;
$menu_bg_col_2: #24333e;
$white: #fff;
$twitter_blue: #00aced;
$light_white: rgba(255, 255, 255, 0.1);
$xtra_lighter_grey: rgba(33, 33, 33, 0.05);
$xtra_lighter_white: rgba(255, 255, 255, 0.05);
$olive: #889362;
$system_green: #77a975;

//Transitions
$default_transtion: all ease 0.3s;

//Sizes
$sidenav-normal-width: 240px;
$sidenav-mobile-width: 44px;

.w-10 {
  width: 10%;
}
.w-20 {
  width: 20%;
}
.w-30 {
  width: 30%;
}
.w-40 {
  width: 40%;
}
.w-50 {
  width: 50%;
}

html {
  scroll-behavior: smooth;
}

div.dropdown-menu {
  margin: 5px 0;
}

.btn-light:hover {
  background-color: #ddd;
}

.bg-green {
  background: $green !important;
}
.bg-grey {
  background: $grey !important;
}
.bg-light-grey {
  background: $light_grey !important;
}
.bg-yellow {
  background: $yellow !important;
}
.bg-light-yellow {
  background: lighten($color: $yellow, $amount: 30%);
}
.bg-red {
  background: $red !important;
}
.bg-light-red {
  background: lighten($color: $red, $amount: 30%);
}
.bg-blue {
  background: $blue !important;
}
.bg-pink {
  background: $pink !important;
}
.bg-light {
  background: $white !important;
}
.bg-dark {
  background: $black !important;
}
.bg-twitter {
  background: $twitter_blue !important;
}
.txt-dark {
  color: $black !important;
}
.txt-light {
  color: $white !important;
}
.txt-grey {
  color: $grey !important;
}
.txt-light-grey {
  color: $light_grey !important;
}
.txt-info,
.text-info {
  color: $pink !important;
}
.txt-warning,
.text-warning {
  color: $yellow !important;
}
.txt-primary,
.text-primary {
  color: $blue !important;
}
.txt-success,
.text-success {
  color: $green !important;
}
.txt-danger,
.text-danger {
  color: $red !important;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: rgba(var(--bs-secondary-bg-subtle), 0.2);
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--bs-primary);
}

*:focus {
  outline: none !important;
}

select:focus,
input:focus,
textarea:focus,
button:focus {
  box-shadow: none !important;
}

.flex {
  display: flex;
}
.flex-row {
  flex-flow: row;
}
.flex-col {
  flex-flow: column;
}
.flex-wrap {
  flex-wrap: wrap;
}

.flex-1 {
  flex: 1;
}
.flex-0 {
  flex: 0;
}

.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 15px;
}

.gap-20 {
  gap: 15px;
}

.flex-card-container {
  display: flex;
  flex: 1;
  flex-flow: column;
}

.flex > .card {
  flex: 1;
}

.flex-with-checkbox {
  display: flex;
  flex-flow: row;
  justify-items: center;
  align-items: center;
  > div {
    display: flex;
  }
  label {
    margin: 0;
  }
}
.flex-center {
  align-items: center;
  //gap: 5px;
}

.flex-end {
  align-items: flex-end;
  //gap: 5px;
}

.pagination .page-item.active > span {
  background-color: $blue;
  border-color: $blue;
  color: $white;
}

.pagination {
  input,
  input:focus,
  input:active {
    background-color: $white;
    border-color: #ddd;
    color: $blue;
    font-weight: bold;
    transition: all ease-in-out 0.2s;

    &.error {
      background-color: $red;
      border-color: $red;
      color: $white;
    }

    &::placeholder {
      color: #ccc;
      font-weight: bold;
    }
  }

  .paging-hit-enter {
    position: relative;
    &::before {
      content: " ";
      position: absolute;
      background-color: #222;
      height: 10px;
      width: 10px;
      top: -10px;
      left: 50%;
      transform: translateX(-50%) rotateZ(45deg);
      z-index: 1;
    }
    &::after {
      content: "Enter Page Number then Hit Enter";
      position: absolute;
      background-color: #222;
      color: #fff;
      white-space: nowrap;
      top: -22px;
      left: 50%;
      transform: translateX(-50%);
      padding: 2px 5px;
      border-radius: 3px;
      font-size: 10px;
      z-index: 1;
    }
  }
}

.alert-danger2 {
  text-decoration: line-through;
  font-style: italic;
  background-color: #eee;
  color: $danger;
}

.chip-olive,
.chip-red,
.chip-orange,
.chip-green,
.chip-gray {
  background-color: $olive;
  border-radius: 5px;
  font-size: 11px;
  color: $white;
  padding: 3px 6px;
  border-radius: 50px;

  &.chip-orange {
    background-color: $orange;
  }

  &.chip-red {
    background-color: $red;
  }

  &.chip-green {
    background-color: $green;
  }

  &.chip-gray {
    background-color: var(--bs-light-bg-subtle);
    color: var(--bs-secondary);
  }
}

.mail-link {
  text-decoration: none;
  color: $blue;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.pointer {
  cursor: pointer;
}

.no-wrap {
  white-space: nowrap;
}

.form-control {
  appearance: auto;
}

.form-control[readonly] {
  background-color: var(--bs-light-bg-subtle);
}

.formModalOverlay {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.backdrop-init-position {
  transition: opacity ease-in-out 0.3s;
  opacity: 1;
  &.animate {
    opacity: 0.1;
  }
}

.dialog-init-position {
  transition: all ease 0.2s;
  opacity: 1;
  transform: translateY(0);

  &.animate {
    transform: translateY(-20px);
  }
}

.now-wrap {
  white-space: nowrap;
}

.display-none {
  display: none;
}

hr.light-grey-hr {
  border-top: 1px solid #dedede;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

table.table-sm {
  > thead {
    tr > th {
      color: #777;
      border-color: #ddd;
    }
  }
  > tbody {
    tr > td {
      color: #777;
    }
  }
}

table.table-label-desc {
  width: 100%;
  > tbody {
    > tr > td {
      padding: 0 !important;
      padding-right: 10px !important;
      vertical-align: text-top;
      &:first-child {
        white-space: nowrap;
      }
      &:last-child {
        padding-right: 0 !important;
      }
    }
  }
}

table:not(.table-sm):not(.no-format):not(.table-label-desc) {
  margin-bottom: 0;

  > thead > tr > th {
    font-weight: normal;
    font-size: 12px;
  }
  > tbody > tr > td {
    color: #878787;
    font-size: 16px;
  }
  > tbody > tr > td,
  > thead > tr > th {
    // border-color: #dedede !important;
    // border-bottom-color: #dedede !important;
    padding: 8px !important;

    &:first-child {
      padding-left: 20px !important;
    }
    &:last-child {
      padding-right: 20px !important;
    }
  }
  > thead > tr > th {
    padding-top: 16px !important;
    padding-bottom: 10px !important;
  }
}

table {
  tbody > tr > td {
    vertical-align: top;

    small {
      color: #aaa;
    }
  }
}

table tbody tr td a {
  text-decoration: none;
  color: $blue;
}

button,
textarea,
input,
select {
  border-radius: 0 !important;
}

.DayPickerInput {
  display: block !important;
  //z-index: 10; //Ticket #286
  input {
    display: block;
    border: 1px solid #ced4da !important;
    padding: 0.3rem 0.75rem;
    width: 100%;
  }
}

.sysDateTimePicker {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  gap: 10px;

  > div:first-child {
    flex: 1;
    min-width: 110px;
  }

  > div:last-child {
    display: flex;
    flex-flow: row;
    gap: 10px;
  }

  select {
    width: 70px;
  }
}

.dataRangpicker {
  .sysDateTimePicker {
    input {
      padding: 3px 8px;

      &::placeholder {
        font-size: 14px;
      }
    }
  }
}

.show-on-print {
  display: none;
}

.no-decoration {
  text-decoration: none;
}

.switch-container {
  display: inline-flex;
  gap: 15px;
  // > label {
  //   white-space: nowrap;
  // }
}

button.switch-button {
  transition: all ease 0.3s;
  border: none;
  border-radius: 30px !important;
  background-color: #bbb;
  position: relative;
  max-height: 25px;
  height: 25px;
  width: 55px;
  min-width: 55px;

  &[disabled] {
    opacity: 0.5;
  }

  &::after {
    font-family: "FontAwesome";
    font-weight: 400;
    content: "\f00d";
    color: #fff;
    display: block;
    text-align: right;
    padding: 0 4px;
  }

  &::before {
    transition: all ease 0.3s;
    content: " ";
    position: absolute;
    height: 21px;
    width: 21px;
    border-radius: 50%;
    background-color: #fff;
    top: 2px;
    left: 2px;
  }

  &.switch-on {
    background-color: $blue;
    &::before {
      left: 32px;
    }

    &::after {
      content: "\f00c";
      text-align: left;
    }
  }
}

.overflow-auto {
  overflow: auto;
}

.min-height-100 {
  min-height: 100px;
}

.break-all {
  word-break: break-all;
}

.break-word {
  word-break: break-word;
}

.border-none {
  border: none !important;
}

.search-box {
  width: 350px;
  max-width: 100%;
}

.remirror-button {
  color: rgba(0, 0, 0, 0);
  position: relative;

  &:hover {
    color: rgba(0, 0, 0, 0) !important;
    &::after {
      color: #fff;
    }
  }

  &::after {
    color: blue;
    font-family: "FontAwesome";
    font-weight: 400;
    content: "\f0d7";
    display: block;
    text-align: center;
    position: absolute;
    top: 2px;
    left: 0;
    right: 0;
  }
}

.remirror-theme {
  display: flex;
  flex-flow: column;
  gap: 10px;
  p {
    margin: 0;
    color: #222;
  }
  .remirror-editor-wrapper {
    order: 2;
    //overflow: auto;

    table {
      display: block;
      //width: 100%;
    }

    > div[contenteditable="false"] {
      padding: 0;
      box-shadow: none;
      overflow: hidden;

      img {
        max-width: 100%;
        max-height: 100%;
        height: auto;
      }

      table {
        border: none;
        // th {
        //   display: none;
        // }

        th,
        td {
          border: none;
        }
      }
    }

    blockquote {
      padding-left: 1.5em;
      border-left: solid 2px #ddd;
    }

    code {
      border-radius: 4px;
      border: 1px solid #e8ebed;
      background: #f4f7fa;
      padding: 3px 4px;
      font-size: 80%;
    }

    ul[data-task-list] {
      // list-style: none;
      // padding: 0;
      // padding-left: 12px;
      > li {
        display: flex;
        flex-flow: row;
        align-items: flex-start;
        gap: 8px;

        &[data-checked] {
          text-decoration: line-through;
        }
      }
    }

    [data-text-highlight-mark] {
      color: inherit;
    }
  }
  .doc-editor-toolbar {
    order: 1;

    > .btn-group {
      margin-bottom: 5px;
    }

    .btn {
      > i.fa-font.text-highlight {
        border: solid 1px #222;
        padding: 2px 5px;
      }
      &.btn-secondary {
        > i.fa-font.text-highlight {
          border: solid 1px #fff;
        }
      }
    }

    .default-colors-container {
      padding: 0 15px;
      padding-bottom: 10px;
      display: flex;
      flex-flow: wrap;
      gap: 6px;

      > button {
        flex: 0 0 auto;
        display: inline-block;
        height: 20px;
        width: 20px;
        box-shadow: 0 0 0 1px #ccc;

        &.active-color {
          box-shadow: 0 0 0 2px red;
        }

        &.remove-color {
          position: relative;
          > i {
            color: red;
            position: absolute;
            top: 2px;
            left: 3px;
          }
        }
      }
    }
  }
}

.float-right {
  float: right;
}

.dragging {
  opacity: 0.3;
}

tr.dragover td {
  border-bottom: solid 3px $blue !important;
}

tr.dragover-2 td {
  border-top: solid 3px $blue !important;
}

.dropdown-toggle-hidden {
  // width: 0;
  // opacity: 0;
  // padding: 0;
  &::after {
    content: " ";
    display: none;
  }
}

.rbt-menu.dropdown-menu {
  width: auto !important;
  max-width: 90vh;
  min-width: 100%;
  background: var(--bs-light-bg-subtle) !important;

  > a {
    white-space: unset;
    width: auto !important;
    border-bottom: solid 1px var(--bs-light-border-subtle);

    &:last-of-type {
      border-bottom: none;
    }

    .custom-typeahead-item-menu {
      white-space: normal;
    }
  }
}
.cell-word-break {
  word-break: break-all;
  position: relative;
  > div {
    position: absolute;
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
  }
  > span {
    height: 16px;
    display: block;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
  }
}

.word-break {
  word-break: break-all;
}

.text-error {
  color: $red;
}

.text-information {
  color: $blue;
}

input::placeholder,
textarea::placeholder {
  opacity: 0 !important;
}

.show-placeholder,
input.rbt-input,
.login-page input,
.search-box input {
  &::placeholder {
    opacity: 0.5 !important;
    font-size: 14px !important;
  }
}

.page-item input {
  &::placeholder {
    opacity: 0.8 !important;
  }
}

.bg-hover:hover {
  cursor: pointer;
  background-color: #efefef;
}

.bg-hover2:hover {
  cursor: pointer;
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-no-hover:hover,
.bg-no-hover:active,
.bg-no-hover:focus {
  background-color: inherit;
}

.input-with-help-icon {
  position: relative;
  > i {
    position: absolute;
    right: 10px;
    top: 12px;
    z-index: 100;
  }
  > input,
  > textarea {
    padding-right: 22px;
  }
}

.react-datepicker-popper {
  z-index: 9;
}

.react-datepicker-wrapper {
  width: 100%;

  input {
    padding-bottom: 8px;
    background-color: var(--bs-body-bg);
    border: var(--bs-border-width) solid var(--bs-border-color) !important;
  }
}

.document-body {
  flex: 0 1 auto;
  display: flex;
  flex-flow: row;
  padding: 5px;
  border-radius: 5px;
  align-items: flex-start;
  position: relative;
  background: var(--bs-light-bg-subtle);
  //#efefef; // darken($color: #f4f5f9, $amount: 3%);
  z-index: 8;
  height: calc(100% - 20px);

  > div:first-child {
    flex: 1;
    min-height: 100%;
    overflow: auto;
    background-color: var(--bs-light-bg-subtle);

    [data-empty-text] {
      background: none !important;
    }

    display: flex;

    > div {
      padding: 2px;

      &.remirror-theme {
        flex: 1;

        > div.remirror-editor-wrapper {
          flex: 1;
          padding: 0;

          > div {
            height: 100%;
            > div {
              height: 100%;
              max-height: calc(100vh - 150px);
            }
          }
        }
      }
    }

    textarea {
      border: none;
      border-bottom: solid 1px #ddd;
      &:focus,
      &:active {
        outline: none;
      }
    }
  }
}

.remirror-theme {
  display: flex;
  flex-flow: column;
  gap: 10px;
  p {
    margin: 0;
    color: var(--bs-light-color);
  }
  .remirror-editor-wrapper {
    order: 2;
    padding-top: 0;

    table {
      display: block;
    }

    > div[contenteditable="false"] {
      padding: 0;
      box-shadow: none;
      overflow: hidden;

      table {
        border: none;

        th,
        td {
          border: none;
        }
      }
    }

    blockquote {
      padding-left: 1.5em;
      border-left: solid 2px #ddd;
    }

    code {
      border-radius: 4px;
      border: 1px solid #e8ebed;
      background: #f4f7fa;
      padding: 3px 4px;
      font-size: 80%;
    }

    ul[data-task-list] {
      > li {
        display: flex;
        flex-flow: row;
        align-items: flex-start;
        gap: 8px;

        &[data-checked] {
          text-decoration: line-through;
        }
      }
    }

    [data-text-highlight-mark] {
      color: inherit;
    }
  }
  .doc-editor-toolbar {
    order: 1;

    > .btn-group {
      margin-bottom: 5px;
    }

    .btn {
      > i.fa-font.text-highlight {
        border: solid 1px #222;
        padding: 2px 5px;
      }
      &.btn-secondary {
        > i.fa-font.text-highlight {
          border: solid 1px #fff;
        }
      }
    }

    .default-colors-container {
      padding: 0 15px;
      padding-bottom: 10px;
      display: flex;
      flex-flow: wrap;
      gap: 6px;

      > button {
        flex: 0 0 auto;
        display: inline-block;
        height: 20px;
        width: 20px;
        box-shadow: 0 0 0 1px #ccc;

        &.active-color {
          box-shadow: 0 0 0 2px red;
        }

        &.remove-color {
          position: relative;
          > i {
            color: red;
            position: absolute;
            top: 2px;
            left: 3px;
          }
        }
      }
    }
  }
}

.customer-documentation {
  display: flex;
  flex-flow: row;

  > div:first-child {
    border-right: solid 1px #eee;
    max-width: 300px;
    min-width: 200px;
  }
  > div:last-child {
    flex: 1;
    overflow: auto;
    img {
      max-width: 100%;
    }
  }

  @media (max-width: 700px) {
    > div:first-child {
      max-width: unset;
    }
    flex-flow: column;
  }
}

.selected-document {
  background-color: lighten($system_green, 40%);
  td {
    color: $system_green !important;
    font-weight: bold;
    small {
      font-weight: normal;
      color: $system_green !important;
    }
  }
}

.selected,
tr.selected:hover {
  > td {
    background-color: var(--bs-primary-bg-subtle) !important;
    > div {
      font-weight: bold;
      color: $blue !important;
    }
    > small {
      color: var(--bs-secondary-color) !important;
    }
  }
}

.rbt-highlight-text {
  padding: 0;
}

.text-select-container {
  position: relative;
  > section {
    background-color: #fff;
    position: absolute;
    z-index: 10;
    //box-shadow: 0 0 1px 1px $blue;
    background-color: $blue;
    color: #fff;
    padding: 5px 8px;
    border-radius: 3px;
    top: 10px;
    left: 10px;
    &:hover {
      background-color: darken($blue, 15%) !important;
    }
  }
}

.tab-flex {
  .tab-content {
    flex: 1 1 auto;
    border: solid 1px var(--bs-border-color);
    border-top: none;
    padding: 20px;
    overflow: auto;

    > div:not(.published) {
      height: 100%;
      > div {
        height: 100%;
      }
    }
  }
}

.rbt-aux {
  position: absolute;
  right: 10px;
  top: 10px;
}

.strike {
  text-decoration: line-through;
  font-style: italic;
}

.bio-photo-preview {
  background-color: var(--bs-light-bg-subtle);
  position: relative;

  > i {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 20px;
    color: $red;
    cursor: pointer;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }
}

.file-dragndrop {
  border: dashed 2px var(--bs-secondary-border-subtle);
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  position: relative;

  > div.btn-file-upload {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    opacity: 0;

    > div.form-group > button {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
    }
  }
  &:hover {
    background-color: var(--bs-light-border-subtle);
  }

  &.is-dragover {
    background-color: lighten($color: #189ad6, $amount: 50%);
    color: #189ad6;
    border-color: #189ad6;
    > div.btn-file-upload {
      display: none;
    }
  }
}

ul.nav-tabs {
  > li {
    margin-right: 5px;
  }
}

.modal-content {
  .tabbed-page {
    min-height: 400px;
  }
}

.tabbed-page {
  display: flex;
  flex-flow: column;
  height: 100%;

  > * {
    flex: 0 0 auto;
  }

  > div.tab-content {
    border: solid 1px var(--bs-border-color);
    border-top: none;
    padding: 20px;
    flex: 1 1 auto;
  }
}

.widget-container {
  border: solid 1px var(--bs-primary-bg-subtle);
  background-color: var(--bs-light-bg-subtle);
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.dashboard-howto {
  border: solid 1px var(--bs-primary-bg-subtle);
  background-color: var(--bs-light-bg-subtle);
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.dashboard-icons {
  display: flex;
  padding: 20px;
  gap: 10px;
  border: solid 1px var(--bs-primary-bg-subtle);
  background-color: var(--bs-light-bg-subtle);
  border-radius: 5px;
  flex-wrap: wrap;
  margin-bottom: 20px;

  > div {
    flex: 0 0 50%;
    max-width: 120px;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
    transition: all ease 0.5s;

    a {
      text-decoration: none;
      color: lighten($primary, 10%);
      text-align: center;
      padding: 10px 5px;
      display: block;

      i {
        font-size: 35px;
        padding-bottom: 5px;
      }

      > div {
        color: #bbb;
        white-space: nowrap;
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: auto;
      }
    }

    &:hover {
      background-color: var(--bs-secondary-bg-subtle);
      transition: all ease 0.5s;

      a {
        color: lighten($primary, 0%);
        > div {
          color: $primary;
        }
      }
    }
  }
}

.reply-to-text .remirror-editor,
td .remirror-editor {
  min-height: 0 !important;
}

.date-filter {
  input {
    width: 110px;
    padding-top: 8px;
  }
}

.two-factor-page {
  > div:nth-child(2) {
    position: absolute;
    min-width: 350px;
    max-width: 400px;
    margin: auto;
    top: 40%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.file-item {
  position: relative;
  width: 200px;
  max-width: calc(50% - 10px);
  transition: all ease 0.5s;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 5px;
  }

  i.fa-pencil,
  i.fa-download,
  i.fa-trash {
    position: absolute;
    right: 20px;
    top: 5px;
    padding: 5px;
    color: #888;
    opacity: 0;
  }

  i.fa-trash {
    right: 5px;
  }

  &:hover {
    background-color: var(--bs-secondary-bg-subtle);
    i.fa-pencil,
    i.fa-download,
    i.fa-trash {
      opacity: 1;
    }
  }

  .item-overlay {
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    backdrop-filter: blur(1px);
    background-color: rgba(var(--bs-light-bg-subtle), 0.5);

    > span {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      font-size: 20px;
    }
  }
}

.bread-crumb-container {
  display: flex;
  flex-wrap: wrap;
  max-width: calc(100% - 100px);

  .bread-crumb-path {
    white-space: nowrap;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

//DARK MODE IMPLEMENTATION
@import "darkmode.scss";
