@import "../styles/styles.scss";

.layout {
  display: flex;
  flex-flow: column;
  height: 100vh;
  overflow: hidden;

  > .layout-top {
    flex: 0;
    border-bottom: solid 1px #ddd;

    > nav {
      padding: 5px 0;

      .navbar-brand {
        width: $sidenav-normal-width;
        overflow: hidden;
        transition: $default_transtion;
        flex: 0 1 auto;
        text-align: center;
        margin: 0;
        > a {
          margin: 0;
        }
      }

      > div {
        > .sideNavToggle {
          display: none;
        }

        #navbarNav {
          display: flex;
          flex: 1 1 auto;
          transition: $default_transtion;
          .search-box {
            display: none;
            width: 320px;
            position: relative;
            > input {
              border-radius: 20px;
              border: solid 1px #bbb;
              padding: 6px 12px;
              padding-right: 30px;
              width: 100%;
            }
            > i {
              position: absolute;
              right: 12px;
              top: 10px;
              color: #777;
            }
          }

          > div.navbar-center {
            flex: 1 1 auto;
          }

          > div.navbar-end {
            flex: 0 1 auto;
          }

          .dropdown-menu {
            a > i {
              width: 15px;
              text-align: center;
            }
          }

          > .sideNavToggle {
            display: none;
          }

          #dropdown-profile,
          #dropdown-notification {
            background-color: rgba(0, 0, 0, 0.1);
            border: none;
            padding: 5px;
            border-radius: 50% !important;
            height: 35px;
            width: 35px;

            &::after {
              display: none;
            }

            > i {
              color: var(--bs-secondary-color);
              font-size: 20px;
            }
          }

          #dropdown-notification {
            background-color: rgba(0, 0, 0, 0);
            > i {
              color: #999;
              font-size: 18px;
            }

            span.notif-count {
              position: absolute;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 10px;
              z-index: 10;
              top: 0;
              left: 18px;
              background-color: $primary;
              color: #eee;
              border-radius: 50%;
              padding: 0;
              padding-top: 2px;
              width: 18px;
              height: 18px;
              display: inline-block;
              align-items: center;
              text-align: center;
            }

            &.hasnotif {
              > i {
                color: rgb(220, 53, 69);
                &.shake {
                  animation: shaking 0.83s infinite;
                }
              }
            }
          }

          #dropdown-cart {
            background-color: rgba(0, 0, 0, 0);
            width: auto;

            .cart-counter {
              background-color: $blue;
              padding: 2px 10px;
              border-radius: 5px;
              margin-left: 8px;
              font-weight: bold;
            }
          }

          .cart-dropdown {
            .dropdown-menu {
              border: solid 2px $blue;
              min-width: 220px;

              > div {
                max-height: 500px;
                overflow: auto;

                > div {
                  border-top: solid 1px #ddd;
                  padding: 6px 12px;
                  gap: 10px;
                  &:first-child {
                    border-top: none;
                  }

                  > div:first-child {
                    white-space: nowrap;
                    max-width: 220px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    flex: 1 1 auto;
                  }
                  > div:last-child {
                    flex: 1 1 auto;
                    font-weight: bold;
                    text-align: right;
                    color: $primary;
                    span {
                      background-color: #ddd;
                      padding: 1px 6px;
                      border-radius: 5px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .left-panel-toggle {
      display: inline-block;
      color: $primary;
    }

    @media (max-width: 1400px) {
      > nav {
        .navbar-brand {
          width: 40px;
        }
        > div {
          #navbarNav {
            flex: 1 1 auto;

            > .sideNavToggle {
              display: block;
            }
          }
        }
      }
    }

    @media (max-width: 992px) {
      > nav {
        .navbar-brand {
          //width: $sidenav-normal-width;
          flex: 1 1 auto;
        }
        > div {
          > .sideNavToggle {
            display: block;
          }
          #navbarNav {
            flex: 0 1 auto;
            > .sideNavToggle {
              display: none;
            }
          }
        }
      }
    }
  }

  > .layout-bottom {
    flex: 1;
    background-color: #fff;
    display: flex;
    flex-flow: row;
    height: calc(100% - 119px);

    .overlay {
      display: none;
      z-index: 18;
      &.show {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(var(--bs-secondary-bg-subtle), 0.3);
        backdrop-filter: blur(1px);
      }
    }

    .left-navbar {
      display: flex;
      flex-flow: column;
      background-color: #f7f4ee;
      color: $blue;

      //Default
      width: $sidenav-normal-width;
      position: absolute;
      z-index: 19;
      transition: $default_transtion;
      overflow: hidden;
      left: 0;
      top: 66px;
      bottom: 0;

      @media (max-width: 1400px) {
        width: $sidenav-mobile-width;
        .sub-menu {
          display: none;
        }

        .hide-when-icons-only {
          height: 0;
          transition: $default_transtion;
          img {
            margin-left: -10px;
            margin-top: 6px;
            transition: $default_transtion;
          }
          i.fa-building {
            margin-left: -7px;
            margin-top: 6px;
            transition: $default_transtion;
          }

          strong {
            display: none;
          }
        }

        &.show {
          width: $sidenav-normal-width;
          .sub-menu {
            display: block;
          }

          .hide-when-icons-only {
            height: auto;
            img {
              margin-left: unset;
              margin-top: unset;
            }
            i.fa-building {
              margin-left: unset;
              margin-top: unset;
            }

            strong {
              display: block;
            }
          }
        }
      }

      @media (max-width: 992px) {
        width: 0;
        &.show {
          width: $sidenav-normal-width;
        }
      }
    }

    .main-container {
      flex: 1;
      overflow: auto;
      overflow-y: scroll;
      padding: 20px 30px;
      //border-top: solid 1px #ddd;
      margin-left: $sidenav-normal-width;
      transition: $default_transtion;

      display: flex;
      flex-flow: column;
      > .main-body {
        flex: 1 1 auto;
      }
      > .main-footer {
        flex: 0 1 auto;
        padding-top: 15px;
        font-size: 12px;
        color: #878787;
      }

      @media (max-width: 1400px) {
        margin-left: $sidenav-mobile-width;
      }
      @media (max-width: 992px) {
        margin-left: 0;
        padding: 15px;
      }
    }
  }

  &.qa {
    nav.navbar {
      background-color: lighten($primary, 35%);
    }
    span.navbar-brand {
      position: relative;
      //background-color: #efefef;
      border-radius: 10px;

      &::after {
        content: "QA";
        background-color: $primary;
        color: #fff;
        position: absolute;
        z-index: 1;
        border-radius: 50%;
        left: 4px;
        top: 2px;
        width: 50px;
        height: 50px;
        padding-top: 4px;
        font-size: 25px;
      }
    }
  }
}

.side-nav-panel {
  flex: 0 1 auto;
  text-align: left;
  transition: $default_transtion;
  white-space: nowrap;
  overflow-y: auto;
  overflow-x: hidden;
  padding-bottom: 50px;
  min-width: 240px;

  > div {
    padding: 10px 15px;
    font-size: 11px;
    display: flex;
    align-items: center;
    color: #222;
    text-transform: uppercase;
    transition: $default_transtion;
    position: relative;
    &::before {
      content: "\f141";
      font: normal normal normal 14px/1 FontAwesome;
      transition: $default_transtion;
      color: rgba(0, 0, 0, 0);
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
    }
  }
  > ul {
    padding: 0;

    > li {
      min-width: $sidenav-normal-width;
      overflow: hidden;

      // > div.active {
      //   background-color: lighten($color: $primary, $amount: 40%);
      // }

      a {
        padding: 8px 0 !important;
        display: flex;
        flex-flow: row;
        align-items: center;
        color: $blue;
        font-size: 16px;
        text-decoration: none;
        transition: all ease 0.3s;

        > i {
          flex: 0 0 $sidenav-mobile-width;
          text-align: center;
          color: $blue;

          &.fa-caret-down {
            transition: $default_transtion;
          }
        }
        span {
          flex: 1 1 auto;
          opacity: 1;
          transition: $default_transtion;
          white-space: nowrap;
          padding-right: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      a:not(.active):hover {
        background: var(--bs-secondary-bg-subtle);
        color: $blue;
      }

      a.active {
        background-color: var(--bs-light-bg-subtle);
        color: $blue;
      }

      > ul {
        list-style: none;
        padding-left: 20px;
        transition: $default_transtion;
        > li {
          a {
            font-size: 14px;
          }
        }
      }

      > ul.sub-menu {
        > li {
          transition: $default_transtion;
          height: 0;
          opacity: 0;
          > a {
            font-size: 16px;
            padding: 5px 22px !important;
          }
        }
      }

      &.open {
        i.fa-caret-down {
          transform: rotateZ(-180deg);
        }

        > ul.sub-menu {
          > li {
            height: 34px;
            opacity: 1;
          }
        }
      }
    }
  }
}

@media (max-width: 1400px) {
  .left-navbar:not(.show) {
    .side-nav-panel {
      overflow: hidden;

      > div {
        color: rgba(0, 0, 0, 0);
        &::before {
          color: #777;
        }
      }

      > ul {
        > li {
          a {
            span {
              opacity: 0;
            }
          }

          > ul {
            padding: 0;
          }
        }
      }
    }
  }
}

.sideNavToggle {
  margin-right: 15px;
  > i {
    font-size: 20px;
    color: #878787;
  }
}

.alert-banner-wrapper {
  opacity: 0;
  transition: $default_transtion;
  overflow: hidden;
  margin-bottom: 20px;
  &.show {
    opacity: 1;
  }

  .alert-banner {
    background-color: #fff;
    border: solid 1px #ddd;
    display: flex;
    position: relative;

    > i {
      flex: 0 1 auto;
      padding: 20px;
      background: #eee;
      font-size: 30px;
      color: #777;
      width: 70px;
      text-align: center;
    }

    &.ab-info {
      border-color: $blue;
      i {
        background: $blue;
        color: #fff;
      }
    }

    &.ab-warning {
      border-color: $orange;
      i {
        background: $orange;
        color: #fff;
      }
    }

    &.ab-danger {
      border-color: $red;
      i {
        background: $red;
        color: #fff;
      }
    }

    &.ab-success {
      border-color: $green;
      i {
        background: $green;
        color: #fff;
      }
    }
    > div {
      flex: 1 1 auto;
      padding: 20px;
      padding-right: 40px;
    }
    > button {
      position: absolute;
      background: none;
      border: none;
      top: 10px;
      right: 10px;
      font-size: 15px;
      color: #bbb;
    }
  }
}

.default-page-layout {
  width: 100%;

  > h4 {
    font-family: "EB Garamond", serif;
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.sys-modal {
  img {
    max-width: 100%;
  }
}

.dialog-width-90perc {
  max-width: 90%;
  margin: 1.25rem auto;
}

span.required-label {
  color: #f46a6a;
  margin-right: 2px;
  font-weight: bold;
}

label.required-label {
  &::after {
    content: "*";
    color: #f46a6a;
    margin-left: 2px;
    font-weight: bold;
  }
}

#site-down {
  text-align: center;

  > div {
    max-width: 600px;
    margin: 0 auto;
    padding-top: 30px;

    > img {
      width: 400px;
      margin-bottom: 20px;
    }

    > div {
      border-top: solid 1px #bbb;
      padding-top: 20px;
    }
  }
}

.login-page {
  text-align: center;
  padding: 20px;
  height: 100vh;
  background-size: cover;
  background-position: center;
  overflow: auto;

  > div {
    min-width: 350px;
    max-width: 400px;
    margin: auto;

    &:not(.not-center) {
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    > img {
      width: 100%;
    }

    > div {
      margin-top: 30px;
      padding-top: 20px;
      border-top: solid 1px #dedede;
    }
  }
}

.system-banner {
  z-index: 10;
  width: 100%;
  margin: auto;
  margin-bottom: 20px;
  display: flex;
  flex-flow: row;

  > div:first-child {
    padding: 10px 20px;
    font-size: 30px;
    width: 70px;
    text-align: center;
    background-color: darken($color: $green, $amount: 10%);
  }

  > div:last-child {
    padding: 15px 20px;
    display: flex;
    align-items: center;
  }

  background-color: $green;
  border: solid 1px $green;
  color: #fff;

  a {
    color: #fff;
  }

  &.system-banner-warning {
    background-color: $orange;
    border: solid 1px $orange;

    color: #fff;
    a {
      color: #fff;
    }
    > div:first-child {
      background-color: darken($color: $orange, $amount: 10%);
    }
  }

  &.system-banner-error {
    background-color: $danger;
    border: solid 1px $danger;

    color: #fff;
    a {
      color: #fff;
    }
    > div:first-child {
      background-color: darken($color: $danger, $amount: 10%);
    }
  }
}

.pulse-rose {
  background: red;
}

.pulse-container {
  position: absolute;
  z-index: -1;
  top: 12px;
  left: 13px;
}

.pulse-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  box-shadow: rgba(248, 2, 2, 1);
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(248, 2, 2, 0.5);
  }
  100% {
    box-shadow: 0 0 0 15px rgba(248, 2, 2, 0);
  }
}

@keyframes shaking {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateY(0);
  }
  35% {
    transform: translateY(0) rotate(17deg);
  }
  55% {
    transform: translateY(0) rotate(-17deg);
  }
  65% {
    transform: translateY(0) rotate(17deg);
  }
  75% {
    transform: translateY(0) rotate(-17deg);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}
