html[data-bs-theme="dark"] {
  .App {
    background-color: $dark;

    .layout {
      .layout-top {
        border-bottom: solid 1px #000;
      }
      .layout-bottom {
        background-color: lighten($color: $dark, $amount: 5%);
      }

      .left-navbar {
        background-color: lighten($color: $dark, $amount: 1%);

        .side-nav-panel {
          color: #eee;
          > div {
            color: #eee;
          }

          hr {
            border-color: #000;
          }
        }
      }
    }
  }

  table {
    border-color: #333 !important;

    th {
      border-color: #333 !important;
    }

    tbody {
      border-color: #333 !important;
      tr {
        border-color: #333 !important;
        td {
          border-color: #333 !important;
          color: #ddd !important;
        }
      }
    }
  }

  .text-secondary {
    color: #ddd !important;
  }

  table td .remirror-theme {
    p {
      color: #fff;
    }
  }
}
